.footer {
  grid-row: 3;
  color: white;
  background: hsl(var(--hue), var(--saturation), var(--lightness));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
}

.footer a {
  margin-left: 0.5rem;
}

.footer img {
  height: 2rem;
}
