@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap');

html {
  box-sizing: border-box;
  font-size: 85%;
  --hue: 40;
  --saturation: 30%;
  --lightness: 30%;
  font-family: 'Roboto', sans-serif;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
}

.container {
  display: grid;
  grid-template-rows: 5em 1fr 5em;
  background-color: hsl(var(--hue), var(--saturation), 80%);
  height: 100vh;
}

.main {
  grid-row: 2;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: left;
  background-color: hsl(var(--hue), var(--saturation), 78%);
  color: hsl(var(--hue), var(--saturation), 20%);
  height: 91vh;
  width: 100%;
  overflow: auto;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
