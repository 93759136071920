.header {
  grid-row: 1;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  background: hsl(var(--hue), var(--saturation), var(--lightness));
  padding-left: 2em;
  padding-right: 2em;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
}

.nav-links a {
  text-decoration: none;
  color: white;
  margin-left: 1em;
}

.nav-links button {
  margin-left: 2em;
}

.active {
  border-bottom: white 1px solid;
}
