.para {
  margin-top: 1rem;
  padding: 1rem;
  background-color: hsl(var(--hue), var(--saturation), 52%);
  box-shadow: 7px 5px 5px darkolivegreen;
  width: 87%;
}

.qoute {
  font-family: cursive;
  width: 87%;
  padding: 1rem;
  background-color: hsl(var(--hue), var(--saturation), 52%);
  box-shadow: 7px 5px 5px darkolivegreen;
  margin-bottom: 1rem;
}

.home-image {
  width: 89%;
  height: auto;
  margin: 0.5rem;
  box-shadow: 7px 5px 5px darkolivegreen;
}

/* landing page image free downloaded from https://www.pexels.com/photo/spiral-notebooks-placed-on-white-cloth-with-burning-candle-7150986/ */
