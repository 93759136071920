@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap);
html {
  box-sizing: border-box;
  font-size: 85%;
  --hue: 40;
  --saturation: 30%;
  --lightness: 30%;
  font-family: 'Roboto', sans-serif;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
}

.container {
  display: grid;
  grid-template-rows: 5em 1fr 5em;
  background-color: hsl(40, 30%, 80%);
  background-color: hsl(var(--hue), var(--saturation), 80%);
  height: 100vh;
}

.main {
  grid-row: 2;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: left;
  background-color: hsl(40, 30%, 78%);
  background-color: hsl(var(--hue), var(--saturation), 78%);
  color: hsl(40, 30%, 20%);
  color: hsl(var(--hue), var(--saturation), 20%);
  height: 91vh;
  width: 100%;
  overflow: auto;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header {
  grid-row: 1;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  background: hsl(var(--hue), var(--saturation), var(--lightness));
  padding-left: 2em;
  padding-right: 2em;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
}

.nav-links a {
  text-decoration: none;
  color: white;
  margin-left: 1em;
}

.nav-links button {
  margin-left: 2em;
}

.active {
  border-bottom: white 1px solid;
}

.footer {
  grid-row: 3;
  color: white;
  background: hsl(var(--hue), var(--saturation), var(--lightness));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
}

.footer a {
  margin-left: 0.5rem;
}

.footer img {
  height: 2rem;
}

.para {
  margin-top: 1rem;
  padding: 1rem;
  background-color: hsl(var(--hue), var(--saturation), 52%);
  box-shadow: 7px 5px 5px darkolivegreen;
  width: 87%;
}

.qoute {
  font-family: cursive;
  width: 87%;
  padding: 1rem;
  background-color: hsl(var(--hue), var(--saturation), 52%);
  box-shadow: 7px 5px 5px darkolivegreen;
  margin-bottom: 1rem;
}

.home-image {
  width: 89%;
  height: auto;
  margin: 0.5rem;
  box-shadow: 7px 5px 5px darkolivegreen;
}

/* landing page image free downloaded from https://www.pexels.com/photo/spiral-notebooks-placed-on-white-cloth-with-burning-candle-7150986/ */

.heading {
  font-weight: bold;
}

.card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.5rem;
}

/* .cards {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: hsl(var(--hue), var(--saturation), 55%);
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 7px 5px 5px darkolivegreen;
  overflow: auto;
  width: 90%;
} */

.cards h3 {
  border-bottom: darkgrey solid 0.1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.cards img {
  width: 20%;
  height: auto;
}

.cards:nth-of-type(even) img {
  float: left;
  margin-right: 0.5rem;
}

.cards:nth-of-type(odd) img {
  float: right;
  margin-left: 0.5rem;
}

/* landing page image free downloaded from https://www.pexels.com/photo/spiral-notebooks-placed-on-white-cloth-with-burning-candle-7150986/ */

/*
 portfolio images downloaded from
 https://www.pexels.com/search/technology/

 Photo by Ketut Subiyanto from Pexels
 Photo by fauxels from Pexels
 Photo by Miguel Á. Padriñán from Pexels
 Photo by Polina Zimmerman from Pexels
 */

.heading {
  font-weight: bold;
}

.card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.5rem;
}

.cards {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: hsl(var(--hue), var(--saturation), 55%);
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 7px 5px 5px darkolivegreen;
  overflow: auto;
  width: 82.5%;
}

.cards h3 {
  border-bottom: darkgrey solid 0.1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

/* .cards div {
  margin-top: 0.5rem;
} */

.cards img {
  width: 20%;
  height: auto;
}

.cards:nth-of-type(even) img {
  float: left;
  margin-right: 0.5rem;
}

.cards:nth-of-type(odd) img {
  float: right;
  margin-left: 0.5rem;
}

/* landing page image free downloaded from https://www.pexels.com/photo/spiral-notebooks-placed-on-white-cloth-with-burning-candle-7150986/ */

/*
 portfolio images downloaded from
 https://www.pexels.com/search/technology/

 Photo by Ketut Subiyanto from Pexels
 Photo by fauxels from Pexels
 Photo by Miguel Á. Padriñán from Pexels
 Photo by Polina Zimmerman from Pexels
 */

